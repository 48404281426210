<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col cols="2">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="shownStartDate"
                  :label="$t('Start date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                :locale="getBCPLangCode"
                v-model="startDate"
                @input="changeChartAndDataTable()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="shownEndDate"
                  :label="$t('End date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                :locale="getBCPLangCode"
                v-model="endDate"
                @input="changeChartAndDataTable()"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-combobox
              :items="getCustomersNameAndCode"
              v-model="customerId"
              item-text="Name"
              item-value="Id"
              :label="$t('Customer')"
              @change="changeChartAndDataTable()"
              clearable
              @click:clear="getInitalItems()"
            ></v-combobox>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="6">
        <v-card>
          <v-card-title>
            {{ $t("Popular articles") }}
          </v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("Article code") }}</th>

                    <th class="text-left">{{ $t("Number of") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in reportDataTableItems"
                    :key="index"
                  >
                    <td>
                      <v-avatar class="my-2" color="white">
                        <v-img
                          alt="Avatar"
                          contain
                          :lazy-src="item.Image + '?token=' + getUserToken"
                          :src="item.Image + '?token=' + getUserToken"
                        ></v-img>
                      </v-avatar>
                      {{ item.ItemCode }}
                    </td>
                    <td>{{ item.Total }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card>
          <v-card-title>
            {{ $t("Revenue") }}
          </v-card-title>
          <v-card-text>
            <Bar
              v-if="!isLoading"
              ref="line"
              :chart-options="chartOptions"
              :chart-data="getChartData"
            />

            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import request from "../../request";
/* import { Line as LineChartGenerator } from "vue-chartjs/legacy";
 */ import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import auth from "../../auth";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  data() {
    return {
      startDateMenu: null,
      endDateMenu: null,
      startDate: null,
      endDate: null,
      isLoading: true,
      customers: null,
      customerId: null,

      chartData: {
        labels: [],
        datasets: [
          {
            label: "€",
            backgroundColor: "#b2b2b2",
            borderRadius: 4,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      reportDataTableItems: [],
    };
  },
  components: {
    Bar,
  },
  created() {
    this.endDate = this.changeTimeToReadebleDMY();
    this.startDate = this.changeTimeToReadebleDMY(1);

    this.getInitalItems();
  },
  computed: {
    getChartData() {
      return this.chartData;
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getCustomersNameAndCode() {
      if (!this.customers) {
        return [];
      }
      this.customers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.customers;
    },
    getBCPLangCode() {
      let lang;
      switch (this.currentLanguage) {
        case "Nl":
          lang = "nl-BE";
          break;
        case "Fr":
          lang = "fr-FR";
          break;
        case "En":
          lang = "en-GB";
          break;
      }

      return lang;
    },
    shownStartDate: {
      get: function () {
        var inputDateSplit = this.startDate.split("-");
        return (
          inputDateSplit[2] + "-" + inputDateSplit[1] + "-" + inputDateSplit[0]
        );
      },
    },
    shownEndDate: {
      get: function () {
        var inputDateSplit = this.endDate.split("-");
        return (
          inputDateSplit[2] + "-" + inputDateSplit[1] + "-" + inputDateSplit[0]
        );
      },
    },
    getUserToken() {
      return auth.userToken();
    },
  },
  methods: {
    changeTimeToReadebleDMY(minusYears = 0) {
      let date = new Date(),
        month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1,
        day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(),
        year = date.getFullYear();

      year = year - minusYears;
      let newDate = year + "-" + month + "-" + day;
      return newDate;
    },
    getInitalItems() {
      let startDate = this.startDate ? "startDate=" + this.startDate : "";
      let endDate = this.endDate ? "&endDate=" + this.endDate : "";
      let items = [
        "/api/kdesign/report-items?" + startDate + endDate,
        "/api/kdesign/report-revenue?" + startDate + endDate,
        "/api/kdesign/customers?getAll=true",
      ];
      request.getAll(items, null, (res) => {
        res[0].forEach((element) => {
          element.Image =
            this.$config.apiUrl + "/images/products/" + element.Image;
        });
        this.reportDataTableItems = res[0];
        let total = [],
          yearMonth = [];
        res[1].forEach((element) => {
          total.push(element.Total);
          yearMonth.push(element.YearMonth);
        });
        this.chartData.labels = yearMonth;
        this.chartData.datasets[0].data = total;
        this.customers = res[2];
        this.isLoading = false;
      });
    },

    changeChartAndDataTable() {
      this.startDateMenu = false;
      this.endDateMenu = false;
      let customer = this.customerId ? "&customerId=" + this.customerId.Id : "";
      let startDate = this.startDate ? "startDate=" + this.startDate : "";
      let endDate = this.endDate ? "&endDate=" + this.endDate : "";

      let items = [
        "/api/kdesign/report-items?" + startDate + endDate + customer,
        "/api/kdesign/report-revenue?" + startDate + endDate + customer,
      ];

      this.isLoading = true;
      request.getAll(items, null, (res) => {
        if (res[0]) {
          res[0].forEach((element) => {
            element.Image =
              this.$config.apiUrl + "/images/products/" + element.Image;
          });
          this.reportDataTableItems = res[0];
        }
        if (res[1]) {
          let currency = "€";
          /* if (this.customerId) {
            currency = this.getcurrency(this.customerId);
          } */
          let total = [],
            yearMonth = [];
          res[1].forEach((element) => {
            total.push(element.Total);
            yearMonth.push(element.YearMonth);
          });

          this.chartData.labels = yearMonth;
          this.chartData.datasets[0].label = currency;
          this.chartData.datasets[0].data = total;
        }
        this.isLoading = false;
      });
    },
    getcurrency(item) {
      let currencySign;
      switch (item.Currency) {
        case "EUR":
          currencySign = "€";
          break;
        case "GBP":
          currencySign = "£";
          break;
        case "USD":
          currencySign = "$";
          break;

        default:
          currencySign = "€";
          break;
      }
      return currencySign;
    },
  },
};
</script>

<style>
</style>